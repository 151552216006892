//Globals
var domainList;
var preApproved;

$(function(){
    $("#Header").load("Header.html", function(){
        setActivePage();
    });
    $("#Footer").load("Footer.html");

    $(".members .read-more").on("click", function(){
        var targetDiv = $(this).parent().siblings('.member-info');
        var expanded = targetDiv.height() !== 250 ? true : false;
        $(".member-info").css({"height": "250px", "overflow": "hidden"});
        $(".read-more").html("Read More");
        if(!expanded) {
            targetDiv.css({"height": "auto", "overflow": "visible"});
            $(this).html("Read Less");
        }
    });

    $(".members-mobile .read-more").on("click", function(){
        var targetDiv = $(this).parent().parent().siblings('.member-info-mobile');
        var expanded = $(this).html() === "Read Less" ? true : false;
        $(".member-info-mobile").html("");
        $(".read-more").html("Read More");
        if(!expanded) {
            var person = $(this).data('member');
            targetDiv.html(getMemberHtml(person))
            $(this).html("Read Less");
        }
    });


    getEmailDomains();

    // check if email domain is an approved domain
    $("#RfpEmail").on('blur', function(e) {
        if($(e.currentTarget).val().indexOf('@') != -1) {
            var domain = $(e.currentTarget).val().split('@')[1];
            if (jQuery.inArray(domain, domainList) == -1) {
                $("#AltEmail").show();
                $("#Phone").attr('required', true);
                $("#Institution").attr('required', true);
                $("#Department").attr('required', true);
                preApproved = false;
            }
            else {
                $("#AltEmail").hide();
                $("#Phone").attr('required', false);
                $("#Institution").attr('required', false);
                $("#Department").attr('required', false);
                preApproved = true;
            }
        }
    })
});

function setActivePage(){
    var path = window.location.pathname;
    if(path.indexOf("Index") != -1)
        $(".home").addClass('active');
    else if(path.indexOf("Leadership") != -1)
        $(".leadership").addClass('active');
    else if(path.indexOf("News") != -1)
        $(".news").addClass('active');
    else if(path.indexOf("Contact") != -1)
        $(".contact").addClass('active');
    else if(path.indexOf("RequestForProposal") != -1)
        $(".rfp").addClass('active');
    else if(path.indexOf("PrivatePolicy") != -1 || path.indexOf("TermsOfService") != -1 || path.indexOf("FrequentlyAskedQuestions") != -1){

    }
    else
        $(".home").addClass('active');
}

function toggleMenu(){
    $("#MobileMenu").toggle();
    $("#MobileNav").toggle();
    $("#MobileClose").toggle();
}

function createEmail(){
	var url = '';

	if(window.location.origin=='https://www.poseidoninnovation.com'){
		url = 'https://rfp.poseidoninnovation.com/Contact/ContactUs/';
	}
	else {
		url = 'https://rfp-preview.poseidoninnovation.com/Contact/ContactUs/';
	}

    var firstName = $("#FirstName").val();
    var lastName = $("#LastName").val();
    var email = $("#Email").val();
    var message = $("#Message").val();

    var object = {
        FirstName: firstName,
        LastName: lastName,
        Email: email,
        Message: message,
    };

    $.ajax({
        url:url,
        data: {dto: object},
        type: 'POST',
        success: function(data){
            if(data.success) {
                $("#SubmitButton").attr('disabled', true);
                $("#ContactFormWrapper").empty().html("Thank you for contacting us!").css({"font-size":"28px", "margin-top":"20px"});
                $(".contact-form-error").hide();
            }
            else{
                $(".contact-form-error").show();
            }
        },
        error: function(e){
            $(".contact-form-error").show();
        }
    })
}

function recaptchaCallback() {
    $('#SubmitButton').removeAttr('disabled');
}

function getMemberHtml(person){
    switch (person){
        case "Leff":
            return "Jonathan is a Partner on the Private Transactions team and Chairman of the Deerfield Institute. Jonathan joined Deerfield in 2013 and focuses on venture capital and structured investments in biotechnology and pharmaceuticals. Prior to joining Deerfield, for more than 16 years, Jonathan was with Warburg Pincus, where he led the firm’s investment efforts in biotechnology and pharmaceuticals. Jonathan has also been active in public policy discussions related to healthcare and medical innovation. He previously served as a member of the Executive Committee of the Board of the National Venture Capital Association (NVCA), and previously led NVCA’s life sciences industry efforts as Chair of NVCA’s Medical Innovation and Competitiveness Coalition (NVCA-MedIC). Jonathan also serves on the Emerging Companies Section Board of the Biotechnology Industry Organization. Jonathan is a member of several not-for-profit Boards, including the Spinal Muscular Atrophy Foundation, Friends of Cancer Research, and the Columbia University Medical Center Board of Advisors. Jonathan received his A.B. from Harvard University, and earned his M.B.A. from Stanford Graduate School of Business.";
            break;
        case "Pearlberg":
            return "Joe Pearlberg, M.D., Ph.D., is the Vice President of Scientific Affairs at Deerfield, which he joined in July 2017. Prior to joining Deerfield, he was the Vice President of Clinical Development at Infinity Pharmaceuticals from December 2015 until July 2017. <br/> Prior to Infinity Pharmaceuticals, Dr. Pearlberg worked as a Medical Director in the oncology unit of Sanofi from November 2010 until September 2014. Dr. Pearlberg received his M.D. at the University of California, San Francisco, and completed his clinical training at the Massachusetts General Hospital and Dana Farber Cancer Institute. Dr. Pearlberg also received his Ph.D. in molecular biology from Harvard University and was a Lecturer in the Department of Biological Chemistry and Molecular Pharmacology at Harvard Medical School.";
            break;
        case "Wheeler":
            return "Cameron is a Partner on the Private Transactions team at Deerfield. Cameron joined Deerfield in 2014 and focuses on therapeutics companies. Prior to joining Deerfield, Cameron was at Eleven Biotherapeutics for more than five years, where he was responsible for corporate development and commercial strategy. He was instrumental in the founding, building, and the eventual public offering of Eleven Biotherapeutics. Prior to Eleven, Cameron was at Third Rock Ventures, a Boston-based venture capital firm focused on launching and building life science companies. While at Third Rock, Cameron gained business development and operating experience as a member of the founding team of Constellation Pharmaceuticals. Cameron holds a Ph.D. and S.M. in Biological Engineering and an S.B. in Mechanical Engineering from Massachusetts Institute of Technology.";
            break;
        case "McKerrow":
            return "Dr. James “Jim” McKerrow is the dean of Skaggs School of Pharmacy and Pharmaceutical Sciences at UC San Diego Health Sciences. Dr. McKerrow came to UC San Diego from UC San Francisco, where he served as professor of pathology and director of the Center for Discovery and Innovation in Parasitic Diseases, a consortium of academic and industry scientists dedicated to the discovery and development of new drugs for neglected tropical diseases.<br/>" +
                "<br/>" +
                "Dr. McKerrow has a wealth of experience in natural product research and drug discovery and development. Dr. McKerrow founded and served as chief executive officer of Demeter Pharmaceuticals, a nonprofit company focusing on acquisition and screening of the largest marine natural products library in the United States.<br/>" +
                "<br/>" +
                "He is an active teacher and mentor in graduate and postdoctoral programs, lectures to medical and health profession students and has hosted underrepresented students each year for summer research internships. Committed to fostering science education in the community, he gives talks each year to elementary and high school students and has presented three public lectures in the “Ask a Scientist” series in San Francisco.<br/>" +
                "<br/>" +
                "He is a member of the American Society for Cell Biology, American Society of Microbiology, American Society of Biochemistry and Molecular Biology, American Society of Tropical Medicine and Hygiene, American Society of Parasitologists and the American Society of Immunologists. He currently serves on the editorial boards of Chemical Biology and Drug Design and PLoS Neglected Tropical Diseases. He has co-authored more than a dozen book chapters, published more than 250 articles and has been a keynote speaker at numerous conferences and symposia. His many honors include the Gregor Mendel Honorary Medal from the Academy of Sciences of the Czech Republic and the 2005 Distinguished Alumnus Award from the State University of New York (SUNY), Stony Brook.<br/>" +
                "<br/>" +
                "Dr. McKerrow completed his residency training at UC San Francisco in pathology and his internship in Internal Medicine, as well as his medical degree, at SUNY, Stony Brook School of Medicine. Dr. McKerrow completed his doctoral degree in biology at UC San Diego and received his Bachelor of Science degree in both chemistry and biology at Haverford College in Haverford, Penn.<br/>"
            break;
        case "Roben":
            return "Paul Roben, Ph.D., was named Associate Vice Chancellor for Innovation and Commercialization at UC San Diego in June 2015.<br/> <br/>" +
                "Working with the Vice Chancellor for Research, Dr. Roben is transforming the functions provided by the Technology Transfer Office, Industry Research Alliances, and Industry Contracting into an integrated organization to include patenting, strategic corporate research partnering and industry contracts. He oversees the Office of Innovation and Commercialization, which will establish a campus-wide innovation platform to build a systemic and sustainable innovation culture, create a vibrant regional ecosystem, and accelerate the commercialization of UC San Diego inventions.<br/> <br/>" +
                "Previously he was Senior Director of Office Technology Development at the Salk Institute in La Jolla, working with multiple academic institutes to develop strategic processes to drive innovation alliances, and help translate their research programs into products for patients and society. He guided the formation of multiple new start-up companies and was responsible for the negotiation of a broad spectrum of technology licenses to industry.<br/> <br/>" +
                "As a key member of Ireland’s Innovation Taskforce (2009 to present), he co-authored a national economic-development plan for Ireland, among other achievements.<br/> <br/>" +
                "He earned a Bachelor of Science degree in Biotechnology, as well as his Ph.D., at Dublin City University in Ireland, and studied as a Postdoctoral Fellow with Dr. Greg Silverman at UC San Diego.<br/> <br/>"
            break;
        case "Saltiel":
            return "Dr. Alan Saltiel investigates the hormone insulin and its role in regulating cellular sugar levels, including how cells send and receive signals. Understanding these processes may shed light on dysfunctioning glucose and lipid metabolism, particularly as it related to Type 2 diabetes. He hopes to elucidate the precise function of these pathways and their roles in the pathogenesis of diabetes, with the goal of developing new therapeutic approaches to the treatment of diabetes and related disorders.<br/>" +
                " <br/>" +
                "Dr. Saltiel has spent his research career in both academia and the pharmaceutical industry. His work has been largely devoted to the role of cellular signaling in understanding the actions of insulin and growth factors; beyond a deeper knowledge of diabetes, this work has also led to insights about cancer, heart disease and nervous system disorders. He has an international reputation as an expert on the hormone insulin and its role in diabetes and cellular signaling.<br/> <br/>" +
                "Before he joined the UC San Diego faculty in July 2015, Dr. Saltiel was Mary Sue Coleman Director of the Life Sciences Institute at the University of Michigan. Prior to joining the University of Michigan in 2001, Dr. Saltiel was Distinguished Research Fellow and Senior Director of the Department of Cell Biology at Parke-Davis Pharmaceutical Research Division (now Pfizer Global Research).<br/> <br/>" +
                "Insulin controls cell growth, differentiation of cell types, gene expression, enzyme activity, and the movement of proteins within a cell. His laboratory has pioneered investigations into how signals are segregated into special regions in cells. They discovered a novel group of proteins called “molecular scaffolds” that direct enzymes and other proteins to specific compartments in the cell. These processes insure that the right cell responds in the right way to insulin and other hormones.<br/> <br/>" +
                "Dr. Saltiel holds 18 patents and has extensive experience with the FDA's testing and approval process for new drugs. He has developed drugs for diabetes and cancer.<br/>"
            break;
    }
}

function RfpFormEmail(){
    var firstName = $("#FirstName").val();
    var lastName = $("#LastName").val();
    var email = $("#RfpEmail").val();
    var phone = $("#Phone").val();
    var institution = $("#Institution").val();
    var department = $("#Department").val();

    console.log(email);

    var dto = {
        FirstName: firstName,
        LastName: lastName,
        Email: email,
        Phone: phone,
        Institution: institution,
        Department: department
    };

    var url = '';

    if(window.location.origin=='https://www.poseidoninnovation.com'){
        url = 'https://rfp.poseidoninnovation.com/Account/RequestAccount/';
    }
    else {
        url = 'https://rfp-preview.poseidoninnovation.com/Account/RequestAccount/';
    }

    $.ajax({
        url: url,
        data: dto,
        type: 'POST',
        dataType: "json",
        success: function(data){
            if(data.success) {
                $("#RfpFormWrapper").html(data.message).css("font-size", "28px");
            }
            else{
                $("#formError").html("Error: " + data.message);
                $("#formError").show();
            }
        },
        error: function(e){
            $("#formError").html("Error: " + e.statusText);
            $("#formError").show();
        }
    })
}

function getEmailDomains(){
    var url = '';

    if(window.location.origin=='https://www.poseidoninnovation.com'){
        url = 'https://rfp.poseidoninnovation.com/ApprovedDomain/GetApprovedDomains/';
    }
    else {
            url = 'https://rfp-preview.poseidoninnovation.com/ApprovedDomain/GetApprovedDomains/';
    }
    $.ajax({
        url: url,
        type: 'GET',
        success: function(data){
            if(data.success) {
                domainList = data.approvedDomains;
            }
            else
                domainList = ["deerfield.com", "deerfieldpartners.com", "saberin.com"];
            setupDomainSuggester();
        },
        error: function(e){
            domainList = ["deerfield.com", "deerfieldpartners.com", "saberin.com"];
            setupDomainSuggester();
            console.log("Error: " + e.message);
        }
    });
}

function setupDomainSuggester(){
    // Create and start Domain Suggester
    var EmailDomainSuggester = {
        domains: domainList,
        bindTo: $('#RfpEmail'),

        init: function () {
            this.addElements();
            this.bindEvents();
        },

        addElements: function () {
            // Create empty datalist
            this.datalist = $("<datalist />", {
                id: 'email-options'
            }).insertAfter(this.bindTo);
            // Corelate to input
            this.bindTo.attr("list", "email-options");
        },

        bindEvents: function () {
            this.bindTo.on("keyup", this.testValue);
        },

        testValue: function (event) {
            var el = $(this),
                value = el.val();

            // remove the != -1 if you want the datalist to show up immediately as you type the @
            // with it in place, it won't show up until you type the NEXT character
            if (value.indexOf("@") != -1) {
                value = value.split("@")[0];
                EmailDomainSuggester.addDatalist(value);
            } else {
                // empty list
                EmailDomainSuggester.datalist.empty();
            }

        },

        addDatalist: function (value) {
            // variables for iteration and string concatination
            var i, newOptionsString = "";

            // loop over all the domains in our array
            for (i = 0; i < this.domains.length; i++) {
                newOptionsString +=
                    "<option value='" +
                    value +
                    "@" +
                    this.domains[i] +
                    "'>";
            }
            // add all the <option>s to our datalist
            this.datalist.html(newOptionsString);
        }
    };
    EmailDomainSuggester.init();
}

function AlreadyRegisteredLink(){
	var url = '';

	if(window.location.origin=='https://www.poseidoninnovation.com'){
		url = 'https://rfp.poseidoninnovation.com/';
	}
	else {
		url = 'https://rfp-preview.poseidoninnovation.com/';
	}

	window.location.href = url;
}
